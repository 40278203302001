import { defineStore } from 'pinia';
import { useGlobalContentStore } from './globalContent';
import { useCheckoutStore } from './checkout';
import {
  IProductVariant,
  IShoppingCart,
  PurchaseType,
  ICartItem,
  IAddItemsFromCartResult
} from '~/models/api-types';
import { useVoyadoStore } from './voyado';
import PopUpTypes from '~/constants/pop-up-types';
import * as Sentry from '@sentry/vue';

const { apiPost, apiPut, apiGet, lastError } = useApiFetch();

const defaultCart = {
  items: [],
  totalSum: '',
  checkoutUrl: '',
  delivery: '',
  leadTime: '',
  subTotal: '',
  id: 0,
  vat: '',
  subscriptionScheduleIntervals: [],
  appliedPromotions: [],
  subTotalBeforeDiscount: '',
  purchaseType: PurchaseType.Standard,
  hidePopup: false,
  lastUpdatedCart: PurchaseType.Standard,
};

type State = {
  standardCart: IShoppingCart;
  subscriptionCart: IShoppingCart;
  samplingCart: IShoppingCart;
  error: string;
  isLoading: boolean;
  cartIsFetched: boolean;
  lastUpdatedCart: PurchaseType;
};

export const useCartStore = defineStore('cart', {
  state: (): State => ({
    standardCart: Object.assign({}, defaultCart),
    subscriptionCart: Object.assign({}, defaultCart),
    samplingCart: Object.assign({}, defaultCart),
    error: '',
    isLoading: false,
    cartIsFetched: false,
    lastUpdatedCart: PurchaseType.Standard,
  }),
  actions: {
    async fetchCart() {
      const config = useGlobalContentStore().config;
      const lastUpdatedCart = sessionStorage.getItem('lastUpdatedCart');
      this.lastUpdatedCart = lastUpdatedCart as PurchaseType || PurchaseType.Standard;

      this.isLoading = true;
      const query = `shoppingcarts?countryCode=${config.currentMarket?.countryCode}&language=${config.language}`;
      const data = await apiGet<State>(query);
      if (data) {
        this.subscriptionCart = data.subscriptionCart;
        this.standardCart = data.standardCart;
        this.samplingCart = data.samplingCart;
      }
      this.cartIsFetched = true;

      if (lastError.value) {
        this.error = lastError.value;
      }
      this.isLoading = false;
      Sentry.setTag('basketId', this.standardCart?.id || null);
      Sentry.setTag('subscriptionBasket', this.subscriptionCart?.id || null);
    },
    async addItemToCart({
      item,
      purchaseType,
      hidePopup,
      quantity = 1,
    }: {
      item: IProductVariant;
      purchaseType: PurchaseType | null;
      hidePopup: boolean;
      quantity?: number
    }) {
      const voyadoStore = useVoyadoStore();
      const config = useGlobalContentStore().config;
      const checkoutStore = useCheckoutStore();
      const { $event, $lang } = useNuxtApp();
      sessionStorage.setItem('lastUpdatedCart', purchaseType || PurchaseType.Standard);
      this.lastUpdatedCart = purchaseType || PurchaseType.Standard;

      this.isLoading = true;
      const data = await apiPost<any>(
        `shoppingcart?countryCode=${config.currentMarket?.countryCode}&language=${config.language}`,
        {
          countryCode: config.currentMarket?.countryCode,
          language: config.language,
          item: {
            test: 'test',
            ...item,
            quantity,
          },
          purchaseType,
        }
      );

      if (lastError.value) {
        let message = lastError.value;

        if (!message.message && !message.errorMessage) {
          message = $lang('shoppingCartResources', 'couldNotAddProducts');
        }

        $event('trigger-error-bar', {
          message,
        });
      } else {
        window.dataLayer?.push({
          event: 'addToCart',
          ecommerce: {
            currencyCode: item.currencyCode,
            add: {
              products: [{ ...item?.gtmProperties, quantity: 1 }],
            },
          },
        });

        const cartItem = data.items.find(
          (cartItem: ICartItem) => cartItem.partNo === item.partNo
        );
        if (purchaseType !== PurchaseType.Sampling && !hidePopup) {
          $event('open-added-to-cart-pop-up', {
            type:
              purchaseType === PurchaseType.Subscription
                ? PopUpTypes.SubscriptionItemAdded
                : PopUpTypes.BasketItemAdded,
            data: cartItem,
          });
          checkoutStore.update(purchaseType ? purchaseType : '');
        };

        if (purchaseType === PurchaseType.Subscription) {
          this.subscriptionCart = data;
          voyadoStore.updateCart(purchaseType);
        } else if (purchaseType === PurchaseType.Sampling) {
          this.samplingCart = data;
        } else {
          this.standardCart = data;
          voyadoStore.updateCart(PurchaseType.Standard);
        }
      }

      this.isLoading = false;
    },
    async addPickAndMixItemToCart({
      items,
      purchaseType,
      hidePopup,
      isUpdatedMixPackage,
    }: {
      items: ({ partNo: string | null | undefined; priceListId: number; quantity: number; gtmProperties: any } | { partNo: string; pricelistId: number; quantity: number; gtmProperties: any })[];
      purchaseType: PurchaseType | null;
      hidePopup: boolean;
      isUpdatedMixPackage: boolean
    }) {
      const voyadoStore = useVoyadoStore();
      const config = useGlobalContentStore().config;
      const checkoutStore = useCheckoutStore();
      const { $event, $lang } = useNuxtApp();
      sessionStorage.setItem('lastUpdatedCart', purchaseType || PurchaseType.Standard);
      this.lastUpdatedCart = purchaseType || PurchaseType.Standard;

      this.isLoading = true;
      const data = await apiPost<any>(
        `shoppingcart/pickandmix?countryCode=${config.currentMarket?.countryCode}&language=${config.language}`,
        {
          countryCode: config.currentMarket?.countryCode,
          language: config.language,
          items,
          purchaseType,
        }
      );

      if (lastError.value) {
        let message = lastError.value;

        if (!message.message && !message.errorMessage) {
          message = $lang('shoppingCartResources', 'couldNotAddProducts');
        }

        $event('trigger-error-bar', {
          message,
        });
      } else {
        window.dataLayer?.push({
          event: 'addToCart',
          ecommerce: {
            currencyCode: config.currentMarket?.currencyCode,
            add: {
              products: items.map(item => ({
                ...item.gtmProperties,
                quantity: 1,
              })),
            },
          },
        });

        const cartItem = data.items.find(
          (cartItem: ICartItem) => cartItem.partNo === items[0].partNo
        );
        if (purchaseType !== PurchaseType.Sampling && !hidePopup) {
          $event('open-added-to-cart-pop-up', {
            type:
              isUpdatedMixPackage ? PopUpTypes.BasketItemAddedUpdated :
                purchaseType === PurchaseType.Subscription
                  ? PopUpTypes.SubscriptionItemAdded
                  : PopUpTypes.BasketItemAdded,
            data: cartItem,
          });
          checkoutStore.update(purchaseType ? purchaseType : '');
        };

        if (purchaseType === PurchaseType.Subscription) {
          this.subscriptionCart = data;
          voyadoStore.updateCart(purchaseType);
        } else {
          this.standardCart = data;
          voyadoStore.updateCart(PurchaseType.Standard);
        }
      }

      this.isLoading = false;
    },
    async repurchaseOrder({ orderNumber }: { orderNumber: string }): Promise<boolean | ErrorEvent> {
      const config = useGlobalContentStore().config;
      const voyadoStore = useVoyadoStore();
      const { $event, $lang } = useNuxtApp();
      const { lastError, lastErrorCode } = useApiFetch();

      this.isLoading = true;
      const orderNumberDigits = orderNumber.replace(/\D/g,'');

      const data = await apiPost<IAddItemsFromCartResult>(
        `shoppingcart/${orderNumber}?cartId=${orderNumberDigits}&countryCode=${config.currentMarket?.countryCode}&language=${config.language}&purchaseType=Standard`
      );

      if (lastErrorCode.value) {
        let message = lastError.value;

        if (!message.message && !message.errorMessage) {
          message = $lang('shoppingCartResources', 'couldNotAddProducts');
        }
        $event('trigger-error-bar', {
          message,
        });
        this.isLoading = false;
        return false;
      } else if (data && data.success && data.cart.items.length > 0 && !data.errorMessage) {
        this.standardCart = data.cart;
        voyadoStore.updateCart(PurchaseType.Standard);
        window.dataLayer?.push({
          event: 'addToCart',
          ecommerce: {
            currencyCode: config.currentMarket?.currencyCode,
            add: {
              products: data.cart.items?.map((cartItem) => cartItem.gtmProperties) ?? [],
            },
          },
        });
      } else if (data && data.errorMessage) {
        this.standardCart = data.cart;
        voyadoStore.updateCart(PurchaseType.Standard);
        errorMessage = data.errorMessage;
        $event('trigger-error-bar', {
          errorMessage,
        });
      }
      this.isLoading = false;

      return data.success;

    },
    async alterItemQuantity(
      {
        item,
        purchaseType,
      }: { item: ICartItem; purchaseType: PurchaseType | null }
    ): Promise<void> {
      const config = useGlobalContentStore().config;
      const voyadoStore = useVoyadoStore();
      const checkoutStore = useCheckoutStore();
      const { $event } = useNuxtApp();

      sessionStorage.setItem('lastUpdatedCart', purchaseType || PurchaseType.Standard);
      this.lastUpdatedCart = purchaseType || PurchaseType.Standard;

      this.isLoading = true;
      let currentItemQuantity;

      if (purchaseType === PurchaseType.Subscription) {
        currentItemQuantity = this.subscriptionCart.items.find(
          (cartItem: ICartItem) => cartItem.partNo === item.partNo
        )?.quantity;
      } else {
        currentItemQuantity = this.standardCart.items.find(
          (cartItem: ICartItem) => cartItem.partNo === item.partNo
        )?.quantity;
      }
      if (currentItemQuantity) {
        if (currentItemQuantity > item.quantity) {
          window.dataLayer?.push({
            event: 'removeFromCart',
            ecommerce: {
              currencyCode: config.currentMarket?.currencyCode,
              remove: {
                products: [{ ...item?.gtmProperties, quantity: 1 }],
              },
            },
          });
        } else {
          window.dataLayer?.push({
            event: 'addToCart',
            ecommerce: {
              currencyCode: config.currentMarket?.currencyCode,
              add: {
                products: [{ ...item?.gtmProperties, quantity: 1 }],
              },
            },
          });
        }

        window.dataLayer?.push({
          event: 'Cart',
          eventCategory: 'cart',
          eventAction: 'save',
        });
      }

      window.dataLayer?.push({
        event: 'Cart',
        eventCategory: 'cart',
        eventAction: 'changequantity',
        eventLabel: 1,
      });
      const data = await apiPut<IShoppingCart>(
        `shoppingcart?countryCode=${config.currentMarket?.countryCode}&language=${config.language}`,
        {
          countryCode: config.currentMarket?.countryCode,
          language: config.language,
          item: {
            ...item,
          },
          purchaseType,
        }
      );

      if (lastError.value) {
        const message = lastError.value;
        $event('trigger-error-bar', {
          message,
        });
      } else if (data) {
        if (data.items.length) {
          checkoutStore.update(purchaseType ? purchaseType : '');
        }
        if (purchaseType === PurchaseType.Subscription) {
          this.subscriptionCart = data;
        } else {
          this.standardCart = data;
        }

        voyadoStore.updateCart(purchaseType ? purchaseType : PurchaseType.Standard);
      }
      this.isLoading = false;
    },
    async applyPromoCode(
      { code, purchaseType }: { code: string; purchaseType: PurchaseType | null }
    ) {
      const config = useGlobalContentStore().config;
      const checkoutStore = useCheckoutStore();
      this.isLoading = true;
      const data = await apiPut<IShoppingCart>(
        `shoppingcart/promocode?countryCode=${config.currentMarket?.countryCode}&language=${config.language}`,
        {
          code,
          purchaseType,
        }
      );

      if (lastError.value) {
        this.error = lastError.value;
      } else {
        checkoutStore.update(purchaseType ? purchaseType : '');

        await this.updateIngrid(purchaseType ? purchaseType : '');

        if (purchaseType === PurchaseType.Subscription) {
          this.subscriptionCart = data;
        } else {
          this.standardCart = data;
        }
      }

      this.isLoading = false;
    },
    async deletePromoCode(
      { code, purchaseType }: { code: string; purchaseType: PurchaseType | null }
    ) {
      const config = useGlobalContentStore().config;
      const globalContentStore = useGlobalContentStore();
      const checkoutStore = useCheckoutStore();
      const { $event } = useNuxtApp();

      try {
        this.isLoading = true;
        const data = await $fetch(`/api/shoppingcart/promocode?countryCode=${config.currentMarket?.countryCode}&language=${config.language}`, {
          method: 'DELETE',
          body: {
            data: {
              code,
              purchaseType,
            },
          },
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': globalContentStore.getAcceptLanguage,
            'RequestVerificationToken': globalContentStore.getTheAntiForgeryToken,
          },
        }) as any;

        checkoutStore.update(purchaseType ? purchaseType : '');
        await this.updateIngrid(purchaseType ? purchaseType : '');

        if (purchaseType === PurchaseType.Subscription) {
          this.subscriptionCart = data;
        } else {
          this.standardCart = data;
        }

      } catch (e) {
        let message = e.response?.data?.message || '';
        if (e.response.status === 500) {
          message = $lang('sharedResources', 'genericError');
        }
        $event('trigger-error-bar', {
          message,
        });
        return message;
      } finally {
        this.isLoading = false;
      }
    },
    async updateIngrid(purchaseType: string) {
      const query = `ingrid/update/?purchaseType=${purchaseType}`;
      await apiGet(query);
    },
    async setSubscriptionInterval(
      { interval, purchaseType }
    ): Promise<void> {
      const config = useGlobalContentStore().config;
      const checkoutStore = useCheckoutStore();
      const data = await apiPut<ICartItem>(
        `shoppingcart/interval/${interval}?countryCode=${config.currentMarket?.countryCode}&language=${config.language}&purchaseType=${purchaseType}`
      );
      checkoutStore.update(purchaseType);

      this.subscriptionCart = data;
    },
  },
  getters: {
    cart: (state) => {
      return state.standardCart;
    },
    items: (state) => {
      return state.standardCart?.items;
    },
    subscriptionItems: (state) => {
      return state.subscriptionCart?.items;
    },
    samplingItems: (state) => {
      return state.samplingCart?.items;
    },
  },
});
