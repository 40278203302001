import { type RouterConfig } from '@nuxt/schema';
import { useScrollStore } from '@/store/scrollStore';

// https://router.vuejs.org/guide/advanced/scroll-behavior.html
export default <RouterConfig>{
  scrollBehavior(to, from, savedPosition) {
    // const scrollStore = useScrollStore();

    /* if (savedPosition) {
      return savedPosition;
    } 

    console.log(scrollStore.disableScroll);
    if (scrollStore.disableScroll) {
      return false;
    }*/

    // Check if navigation is caused by query parameter changes
    if (to.path === from.path && to.query !== from.query) {
      return false;
    }

    const nuxtApp = useNuxtApp();
   
    return new Promise(async(resolve) => {
      nuxtApp.hooks.hookOnce('page:finish', async() => {
        await nextTick();
        resolve({
          top: 0,
        });
      });
    });
  },
};
