<template>
  <div
    ref="wysiwyg"
    :class="styleConfig"
    v-html="html"
  />
</template>

<script setup lang="ts">
defineProps<{
  html?: string,
  styleConfig?: { [key: string]: string | boolean; } | string;
}>();

const emit = defineEmits<{
  (event: 'open-policy-modal'): void,
}>();

const wysiwyg = ref();

const isEditOrPreviewMode = computed(() => {
  const route = useRoute();
  return route.query.epieditmode === 'false' || route.query.epieditmode === 'true';
});

onMounted(() => {
  const el = wysiwyg.value;
  const anchorTags = el?.querySelectorAll('a') || [];
  anchorTags.forEach((tag) => {
    // Remove the preview_token from href if not in edit or preview mode
    const href = tag.getAttribute('href');
    if (!isEditOrPreviewMode.value && href && (href.includes('preview') || href.includes('language='))) {
      let newHref = href.replace(/[?&]preview_token=[^&]*/, '');
      newHref = newHref.replace(/[?&]language=[^&]*/, '');
      newHref = newHref.replace(/&$/, '');
      tag.setAttribute('href', newHref.replace(/&/, '?'));
    }

    if (tag.target === '_blank') {
      tag.setAttribute('rel', 'noopener');
    }
    if (tag.href.includes('.pdf')) {
      tag.setAttribute('download', 'download');
      tag.setAttribute('target', '_blank');
      tag.setAttribute('rel', 'noopener');
    }
    if (tag.id === 'openPolicyModal') {
      tag.addEventListener('click', openModal);
    }
    // Add href for open chat button
    if (tag.classList.contains('open-chat-button')) {
      tag.setAttribute('href', 'javascript:$zopim.livechat.window.toggle()');

      // Check if it's a weekday and between 9am-5pm
      const now = new Date();
      const day = now.getDay();
      const hour = now.getHours();

      if (day >= 1 && day <= 6 && hour >= 9 && hour < 15) {
        const icon = tag.querySelector('.chat-icon');
        if (icon) {
          icon.classList.add('bg-success-primary');
        }
      }
    }
  });
});

onBeforeUnmount(() => {
  const el = wysiwyg.value;

  const anchorTags = el?.querySelectorAll('a') || [];
  anchorTags.forEach((tag) => {
    tag.removeEventListener('click', openModal);
  });
});

function openModal(e: MouseEvent) {
  e.preventDefault();
  emit('open-policy-modal');
}

</script>
